.App {
}

.arrow-button {
  position: absolute;
  user-select: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.arrow-button:hover {
  background-color: "blue";
}