.header {
    width: 100%;
    max-width: 100%;
    height: 50px;
    top: 0;
    background-color: #FFF;
    position: fixed;
    z-index: 128;
}

.header-sticky {
    width: 100%;
    max-width: 100%;
    height: 50px;
    top: 0;
    background-color: #FFF;
    position: fixed;
    z-index: 128;
    box-shadow: 0 3px 3px rgba(0,0,0,0.1);
}