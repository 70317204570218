.middle-horizontal-container {
  position: relative;
  height: 8rem;
  margin: 14rem 0;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
}

.middle-horizontal-container h1 {
  font-size: 3em;
  font-weight: lighter;
  color: #222;
  letter-spacing: 2px;
  margin: 0px;
}

.middle-horizontal-container h2 {
  font-size: 3em;
  font-weight: lighter;
  color: #222;
  letter-spacing: 2px;
  margin: 0px;
}