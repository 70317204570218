.horizontal-bar-down {
    box-shadow: 0 3px 3px rgba(0,0,0,0.1);
}

.horizontal-bar-up {
    box-shadow: 0 -3px 3px rgba(0,0,0,0.1);
}

.horizontal-bar-down,
.horizontal-bar-up {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 5px;
    z-index: 50;
}